'use strict';
/**
 * The category list
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} Modal - to create modal object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.jobs')
    .controller('jobCreationController', function($scope, $state, $modal, Modal, DataManager, Notification) {

  $scope.job = {
    machineType: 'manual',
    transport: 'cpformplast',
    fab_fob: 'cpformplast',
    subjobs: []
  };
  $scope.subjob = {};
  $scope.itemCount = 1;
  $scope.jobItems = [{}, {}, {}];

  $scope.minDate = new Date();

  $scope.categories = [];
  $scope.uniqueCategories = [];
  $scope.gage = undefined;
  $scope.width = undefined;

  $scope.items = [];
  $scope.clients = [];

  $scope.subjobSubmitted = false;
  $scope.submitted = false;
  let initialSpeed = 1;

  /**
   * Initialisation function
   * If we update, fetch the category with his id
   * otherwise we create a new one
   */
  $scope.init = function() {

    if ($state.params.jobId) {
      DataManager.fetchJob($state.params.jobId)
        .then(job => {
          for (var i=0 ; i<job.subjobs.length ; i++) {
            job.subjobs[i].date = new Date(job.subjobs[i].date);
          }
          $scope.job = job;
          $scope.gage = job.item.category.dimensions.gage;
          $scope.width = job.item.category.dimensions.width;

          $scope.jobItems[0] = job.item;
          $scope.jobItems[0].differentDie = $scope.jobItems[0].die !== '' && $scope.jobItems[0].die !== undefined && $scope.jobItems[0].die !== $scope.jobItems[0].mold;
          $scope.jobItems[0].moldQuery = job.item.mold;
          initialSpeed = job.item.speed;

          if ($scope.job.secondItem) {
            $scope.jobItems[1] = $scope.job.secondItem;
            $scope.jobItems[1].differentDie = $scope.jobItems[1].die !== '' && $scope.jobItems[1].die !== undefined && $scope.jobItems[1].die !== $scope.jobItems[1].mold;
            $scope.jobItems[1].moldQuery = $scope.jobItems[1].mold;
            $scope.itemCount = 2;
          }

          if ($scope.job.thirdItem) {
            $scope.jobItems[2] = $scope.job.thirdItem;
            $scope.jobItems[2].differentDie = $scope.jobItems[2].die !== '' && $scope.jobItems[2].die !== undefined && $scope.jobItems[2].die !== $scope.jobItems[2].mold;
            $scope.jobItems[2].moldQuery = $scope.jobItems[2].mold;
            $scope.itemCount = 3;
          }

        })
        .catch(err => console.log(err))
    }

    // Fetch items
    DataManager.fetchItems(false).then((data) => {
      $scope.items = data;
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un probème est survenu lors du chargement des items');
    });

    // Fetch clients
    DataManager.fetchClients().then((data) => {
      $scope.clients = data;
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un probème est survenu lors du chargement des clients');
    });

    // Fetch categories
    DataManager.fetchCategories(true)
    .then(data => {
      $scope.categories = data;
    })
    .catch(err =>{
      console.log(err);
      Notification.error("Un problème est survenu lors du chargement des catégories");
    });

  };

  $scope.onMoldChange = function(itemIndex = 0) {
    $scope.jobItems[itemIndex] = { moldQuery: $scope.jobItems[itemIndex].moldQuery };
  };

  $scope.setItem = function(item, itemIndex = 0) {
    $scope.jobItems[itemIndex] = { ...item };
    $scope.jobItems[itemIndex].moldQuery = item.mold;
    $scope.jobItems[itemIndex].differentDie = $scope.jobItems[itemIndex].die !== '' && $scope.jobItems[itemIndex].die !== undefined && $scope.jobItems[itemIndex].die !== $scope.jobItems[itemIndex].mold;
  };

  $scope.archive = function(archive, form){
    DataManager.archiveJob($scope.job._id, archive)
    .then((data) => {
      $scope.job.archive = archive;
      const msp = archive ? 'archivée' : 'réinitialisée';
      Notification.success(`La job <strong>${$scope.job.number}</strong> fut ${msp}`);
    })
    .catch(err => {
      Notification.error('Un problème est survenu');
    });
  };

  $scope.submitJob = function(form, modlForm, create) {
    $scope.submitted = true;

    if ($scope.itemCount > 1 && form.$valid) {
      $scope.saveJob(form, create);
      return;
    }

    if (!form.$valid || !modlForm.name.$valid || !modlForm.code.$valid || ($scope.jobItems[0].differentDie && !modlForm.die.$valid || !modlForm.unitPrice.$valid || !modlForm.quantityPerBox.$valid || !modlForm.quantityPerSkid.$valid) ) {
      Notification.error('Le formulaire de est incomplet');
      return false;
    }
    $scope.saveItem(form, modlForm, create)
  };

  $scope.submitSubjob = function(form) {
    $scope.subjobSubmitted = true;
    if (!form.$valid) {
      return false;
    }
    for (var i=0 ; i<$scope.job.subjobs.length ; i++) {
      if ($scope.job.subjobs[i].index === $scope.subjob.index) {
        Notification.error('Une production avec cet index existe déjà');
        form.index.$error.number = true;
        return false;
      }
    }
    if (!$scope.subjob.po) {
      $scope.subjob.po = $scope.job.po;
    }

    $scope.job.subjobs.push($scope.subjob);
    $scope.subjob = {};
    $scope.subjobSubmitted = false;
  };

  // ELEMENT SAVING

  $scope.saveItem = function(form, modlForm, create) {
    $scope.getCategoryId();

    $scope.jobItems[0].mold = modlForm.mold.$viewValue;

    var promise ;
    if (!$scope.jobItems[0]._id) {
      promise = DataManager.createItem($scope.jobItems[0]);
    } else {
      promise = DataManager.updateItem($scope.jobItems[0]);
    }

    promise.then((data) => {
      $scope.jobItems[0]._id = data._id;
      $scope.jobItems[0].id = data._id;

      $scope.saveJob(form, create);
    })
    .catch(err => {
      Notification.error('Un problème est survenu');
    });
  };

  $scope.saveJob = function(form, create) {
    $scope.job.itemId = $scope.jobItems[0]._id;
    $scope.job.secondItemId = $scope.jobItems[1] && $scope.itemCount>1 ? $scope.jobItems[1]._id : null;
    $scope.job.secondItem = $scope.jobItems[1] && $scope.itemCount>1 ? $scope.jobItems[1] : null;
    $scope.job.thirdItemId = $scope.jobItems[2] && $scope.itemCount>2 ? $scope.jobItems[2]._id : null;
    $scope.job.thirdItem = $scope.jobItems[2] && $scope.itemCount>2 ? $scope.jobItems[2] : null;

    var promise ;

    if (!$scope.job._id) {
      promise = DataManager.createJob($scope.job);
    } else {
      promise = DataManager.updateJob($scope.job);
    }

    promise.then((data) => {
      form.number.$error.unique = false;
      form.number.$valid = true;
      form.number.$invalid = false;

      $scope.job._id = data._id;
      for (var i=0 ; i<$scope.job.subjobs.length ; i++) {
        $scope.saveSubjob($scope.job.subjobs[i], create)
      }
      $scope.detectSaveCompletion(create);
    })
    .catch(err => {
      if (err.data.err.code == 11000) {
        form.number.$error.unique = true;
        form.number.$valid = false;
        form.number.$invalid = true;
        Notification.error('Cette job existe déjà');
      } else {
        Notification.error('Un problème est survenu');
      }
    });
  };

  $scope.saveSubjob = function(subjob, create) {
    subjob.saved = false;
    subjob.deleted = false;

    var promise;
    if (!subjob._id && !subjob.toDelete) {
      promise = DataManager.createSubjob($scope.job._id, subjob);
    } else if (subjob._id && !subjob.toDelete) {
      promise = DataManager.updateSubjob($scope.job._id, subjob);
    } else if (subjob._id && subjob.toDelete) {
      promise = DataManager.deleteSubjob($scope.job._id, subjob);
    } else {
      subjob.deleted = true;
      $scope.detectSaveCompletion(create);
      return;
    }

    promise.then((data) => {
      if (subjob.toDelete) {
        subjob.deleted = true;
      } if (data) {
        subjob._id = data._id;
        subjob.saved = true;
      }
      $scope.detectSaveCompletion(create);
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un problème est survenu');
    });
  };

  $scope.detectSaveCompletion = function(create) {
    for (var i=0 ; i<$scope.job.subjobs.length ; i++) {
      if (!$scope.job.subjobs[i].saved && !$scope.job.subjobs[i].deleted) {
        return false;
      }
    }

    Notification.success(`La job <strong>${$scope.job.number}</strong> fut enregistrée`);
    var modalInstance = $modal.open({
      animation: true,
      templateUrl: '../../components/modal/info/info-job-modal.html',
      controller: 'InfoJobModalController',
      resolve:{
        job: function () {
          return $scope.job;
        },
        item: function () {
          return $scope.jobItems[0];
        }
      }
    });
  };

  // CATEGORY STUFF

  $scope.updateGageAndWidht = function(){

    $scope.categories.forEach(function(category){
      if(category.name == $scope.jobItems[0].category.name && category.dimension == $scope.jobItems[0].category.dimension){
        $scope.gage = category.dimensions.gage;
        $scope.width = category.dimensions.width;
      }
    });
  };

  $scope.getCategoryId = function(){
    $scope.categories.forEach(function(category){
      if(category.name == $scope.jobItems[0].category.name && category.dimension == $scope.jobItems[0].category.dimension){
        $scope.jobItems[0].category._id = category._id;
      }
    });
  };

  $scope.filterForDimension = function(){
    let categories = $scope.categories,
        name,_id;

    try{
      name = $scope.jobItems[0].category.name;
      _id  = $scope.jobItems[0].category._id;
    }catch(err){
      name = undefined;
      _id = undefined;
    }

    return categories.filter(category => category.name === name && (!category.archive || category._id === _id)).sort((a,b) => a.dimension < b.dimension ? -1 : 1);
  };

  $scope.filterForName = function(){
    let categories = $scope.categories,
        filteredCategories = [],
        _id;

    try {
      _id = $scope.jobItems[0].category._id;
    }catch(err){
      _id = undefined;
    }

    categories
      .sort((a,b) => {
        if (a.archive && !b.archive) {
          return 1;
        }else if (!a.archive && b.archive) {
          return -1
        }else if (a.name > b.name) {
          return 1;
        }else if (a.name < b.name) {
          return -1;
        }else {
          return null;
        }
      })
      .forEach(category => {
        let foundedCat = filteredCategories.find(cat => cat.name === category.name);
        if(!foundedCat && (!category.archive || category._id === _id)){
          filteredCategories.push(category);
        }
    });

    return filteredCategories;
  };

  $scope.openCategoryCreationModal = () => {
    const modalInstance = $modal.open({
      animation: true,
      templateUrl: '../../components/modal/category/category-creation-modal.html',
      controller: 'CategoryCreationModal'
    });

    modalInstance.result.then(null, function() {
      DataManager.fetchCategories(true)
        .then(data => {
          $scope.categories = data;
        })
        .catch(err =>{
          console.log(err);
        });
    });
  };

  $scope.onSpeedChange = () => {
    // Allow empty field
    if ($scope.jobItems[0].speed === null) return;
    else if (isNaN(parseInt($scope.jobItems[0].speed))) $scope.jobItems[0].speed = 1;
    else if (parseInt($scope.jobItems[0].speed) < 1) $scope.jobItems[0].speed = 1;
  }

  $scope.onSpeedBlur = () => {
    if ($scope.jobItems[0].speed === null) {
      $scope.jobItems[0].speed = initialSpeed;
    }
  }
  
  $scope.getDisabledTooltip = (disabled) => {
    if (disabled) {
      return 'Impossible de supprimer:<br />Déjà associé à une machine';
    } else {
      return null;
    }
  }

  $scope.init();
});
